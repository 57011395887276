import React, { useEffect, useCallback,  } from "react";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import SignInForm from "./form/SignInForm";
import * as actions from "../../redux/actions/authActions/auth.actions";
import BoxCenter from "./UI/box/Center";
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import { logo } from './../../assets/images';
import { ToastProvider, useToasts } from 'react-toast-notifications';

const SignIn = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts()
  const signIn = useCallback(
    (user, hist, addToast) => dispatch(actions.signIn(user, hist, addToast)),
    [dispatch]
  );

  const isFetching = useSelector(state => state.authReducer.isFetching);

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  useEffect(()=> {
   if(isLoggedIn)
   props.history.push("/");
  }, [isLoggedIn])

  const login = (user) => {
      signIn(user, props.history, addToast)
      .catch((ex) => {
        alert(ex.message);
      });
  }

  return (
    <BoxCenter logIntoReg={()=>props.history.push('/')}>
      
        <img src={logo} width="200px" height="80px"/>
      <Typography component="h1" variant="h5" style={{fontFamily: 'Roboto', marginTop: 30, marginBottom: 20, textTransform:'none', fontWeight: 'bold', fontSize: 20, color: '#2E384D'}}>
        Sign in
      </Typography>
      <SignInForm
        handleSubmit={(data) => login(data)}
        isFetching={isFetching}
        
      />
      {/* <Typography variant="body2" style={{marginTop: 30}}>
        or
      </Typography>
      <Typography variant="body2">
        sign in with
      </Typography>
      <div style={{marginTop: 15}}>

        <Button onClick={()=>alert('Данный метод недоступен')} style={{width: 120, height: 40, textTransform: 'none', backgroundColor:"#4267B2", color: '#fff', marginRight: 5}} variant="contained">Facebook</Button>
        <Button onClick={()=>alert('Данный метод недоступен')} style={{width: 120, height: 40, textTransform: 'none', backgroundColor:"#fff", color: '#000', marginLeft: 5}} variant="contained">Google</Button>
      </div> */}

      <Typography variant="body2" style={{marginTop: 35}}>
        Don’t have an account?
      </Typography>
      <Button 
      fullWidth 
      style={{backgroundColor:"#2E5BFF", color: '#fff', marginTop:15, height:50, textTransform: 'none', fontWeight:'bold', fontSize: 14}}
            variant="contained"
            onClick={()=>props.history.push('/register')}> Click to register</Button>
    </BoxCenter>
  );


}

export default SignIn;
