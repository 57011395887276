import AuthService from "../../../services/auth.service";
import * as actionTypes from './actions.types';
import store from 'store';
import Toast from './../../../components/toast';
import { Link } from 'react-router-dom';


export const setIsFetching = (isFetching) => {
  return {
    type: actionTypes.IS_FETCHING,
    isFetching,
  };
};

export const isSignedInUser = (isLoggedIn) => {
  return {
    type: actionTypes.IS_LOGGED_IN,
    isLoggedIn,
  };
};

export function signIn(user, hist, addToast) {
  
  return async (dispatch) => {
    await dispatch(setIsFetching(true));
    await AuthService.loginUser(user)
      .then( (response) => {
        debugger
        if(response.data.code ===200 || response.data.code ===201){

          addToast('Success',  { appearance: 'success', autoDismissTimeout: 2000, autoDismiss : true })

          window.location.href = 'https://home.fluidsens.embedded.biz.ua/';
        } 

        if(response.data.code ===405&&response.data.msg==="Модификаторы уровня '$type' Не заданы"){
          addToast("Level modifiers '$ type' Not set",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===405&&response.data.msg==="Переменные пустые или не распознаны"){
          addToast("Variables are empty or not recognized",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===405&&response.data.msg==="Метод не [DELETE]"){
          addToast("Method not [DELETE]",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        if(response.data.code ===414&&response.data.msg==="Значение поля 'login' превысило допустимую длинну"){
          addToast("The value of the 'login' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'userkey' превысило допустимую длинну"){
          addToast("The value of the 'userkey' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'auth' превысило допустимую длинну"){
          addToast("The value of the 'auth' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'pass' превысило допустимую длинну"){
          addToast("The value of the 'pass' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
      
        if(response.data.code ===414&&response.data.msg==="Значение поля 'mail' превысило допустимую длинну"){
          addToast("The value of the 'mail' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'akk' превысило допустимую длинну"){
          addToast("The value of the 'akk' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'loginkey' превысило допустимую длинну"){
          addToast("The value of the 'loginkey' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'passkey' превысило допустимую длинну"){
          addToast("The value of the 'passkey' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===102&&response.data.msg==="Аккаунт пользователя найден"){
          addToast("Account is not defined",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===401&&response.data.msg==="Отказано в доступе, несовпадение ключей"){
          addToast("Access denied, key mismatch",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===401&&response.data.msg==="Не авторизовано"){
          addToast("Not authorized",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===409&&response.data.msg==="Логин верен, пароль нет"){
          addToast("Login correct, no password",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===431&&response.data.msg==="Превышена допустимая длинна у переменных"){
          addToast("Variables are too long",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        if(response.data.code ===204&&response.data.msg==="Аккаунт пользователя не найден"){
          addToast("Account is not defined",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===204&&response.data.msg==="Пользователь не найден"){
          addToast("Account is not defined",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        

      })
      .catch((error) => {
        addToast('error',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        dispatch(setIsFetching(false));
      });
  };
}

export function signUp(user, hist, addToast) {

  return async (dispatch) => {
    await dispatch(setIsFetching(true));
    await AuthService.registerUser(user)
      .then( (response) => {
        

        if(response.data.code === 200 || response.data.code ===201){
          addToast('Success',  { appearance: 'success', autoDismissTimeout: 2000, autoDismiss : true })
          hist.push('/');
        } 
        if(response.data.code ===405&&response.data.msg==="Модификаторы уровня '$type' Не заданы"){
          addToast("Level modifiers '$ type' Not set",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===405&&response.data.msg==="Переменные пустые или не распознаны"){
          addToast("Variables are empty or not recognized",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===405&&response.data.msg==="Метод не [DELETE]"){
          addToast("Method not [DELETE]",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        if(response.data.code ===414&&response.data.msg==="Значение поля 'login' превысило допустимую длинну"){
          addToast("The value of the 'login' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'userkey' превысило допустимую длинну"){
          addToast("The value of the 'userkey' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'auth' превысило допустимую длинну"){
          addToast("The value of the 'auth' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'pass' превысило допустимую длинну"){
          addToast("The value of the 'pass' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
      
        if(response.data.code ===414&&response.data.msg==="Значение поля 'mail' превысило допустимую длинну"){
          addToast("The value of the 'mail' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'akk' превысило допустимую длинну"){
          addToast("The value of the 'akk' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'loginkey' превысило допустимую длинну"){
          addToast("The value of the 'loginkey' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===414&&response.data.msg==="Значение поля 'passkey' превысило допустимую длинну"){
          addToast("The value of the 'passkey' field has exceeded the allowed length",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===102&&response.data.msg==="Аккаунт пользователя найден"){
          addToast("Account is not defined",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===401&&response.data.msg==="Отказано в доступе, несовпадение ключей"){
          addToast("Access denied, key mismatch",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===401&&response.data.msg==="Не авторизовано"){
          addToast("Not authorized",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===409&&response.data.msg==="Логин верен, пароль нет"){
          addToast("Login correct, no password",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===431&&response.data.msg==="Превышена допустимая длинна у переменных"){
          addToast("Variables are too long",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        if(response.data.code ===204&&response.data.msg==="Аккаунт пользователя не найден"){
          addToast("Account is not defined",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===204&&response.data.msg==="Пользователь не найден"){
          addToast("Account is not defined",  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        // const {token, isAdmin, nickNameColor, nickName} = response.data;
        // store.set("token", token);
        // store.set("userData", {isAdmin, nickName, nickNameColor});

        // dispatch(setIsFetching(false));
        // dispatch(isSignedInUser(true));
      })
      .catch((error) => {
        addToast('error',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        dispatch(setIsFetching(false));
      });
  };
}
