import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastProvider, useToasts } from 'react-toast-notifications';

ReactDOM.render(
  <ToastProvider>
  <App />
</ToastProvider>
    ,
  document.getElementById('root')
);

serviceWorker.unregister();
