import { URL_API } from "../environment";
import axios from "axios";

export default class AuthService {
  static loginUser = (user) => {
    return axios.put(`${URL_API}/sys/account.php?type=auth`, user, {headers: {"Access-Control-Allow-Origin": "*"}})
  };
  static registerUser = (user) => {
    debugger
    return axios.post(`${URL_API}/sys/account.php?type=reg_new_user`, user)
  };
}
