import React, { useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useInput} from '../../hooks/useInput'
import Cookies from 'js-cookie';

const SignInForm = ({ isFetching, handleSubmit }) => {

  const { value:username, bind:bindUserName, reset:resetUserName, setValue } = useInput('');
  const { value:password, bind:bindPassword, reset:resetPassword, setValue: sP } = useInput('');

  useEffect(() => {
    ValidatorForm.addValidationRule("moreThanThreeChar", (value) => {
      return value.length >= 6;
    });
    ValidatorForm.addValidationRule("notAllowedSpecialSymbols", (value) => {
      return !(/[^A-zА-яЁё0-9]/.test(value));
    });

    return () => {
      ValidatorForm.removeValidationRule("moreThanThreeChar");
      ValidatorForm.removeValidationRule("moreThanThreeChar");
    }
  }, [ValidatorForm]);

  useEffect(()=> {
    let login =  Cookies.get('login');
    if(login){
      setValue(login);
      sP('')
    } else {
      setValue('');
      sP('')
    }
  }, [])


  const handleSubmitCallback = useCallback((evt) => {
    evt.preventDefault();
    handleSubmit({login:username, pass: password});
  }, [username, password, handleSubmit]);

  return (
    <ValidatorForm  onSubmit={handleSubmitCallback}>
      <input name="fake_email" class="visually-hidden" type="text" />
      <input name="fake_password" class="visually-hidden" type="password"/>
      <TextValidator
        fullWidth
        label="Username"
        name="username"
        variant="outlined"
        autoComplete='off'
        placeholder='Enter login'
        {...bindUserName}
        validators={[
          "required",
          "moreThanThreeChar",
          "notAllowedSpecialSymbols",
        ]}
        errorMessages={[
          "this field is required",
          "6 characters minimum",
          "Not allowed special symbols",
        ]}
        margin="dense"
      />
      <TextValidator
        placeholder='Enter password'
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Password"
        type="password"
        {...bindPassword}
        name="password"
        validators={["required"]}
        errorMessages={["this field is required"]}
        margin="dense"
        autoComplete='off'
      />
      <Button
        style={{backgroundColor:"#2E5BFF", color: '#fff', marginTop:15, height:50, textTransform: 'none', fontWeight:'bold', fontSize: 14}}
        variant="contained"
        fullWidth
        type="submit"
        margin="normal"
      >
       Click to login
      </Button>
    </ValidatorForm>
    
        
  );
}




export default SignInForm;
