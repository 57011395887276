import Cookies from 'js-cookie';
export const isAuthGuard = (to, from, next) => {
    if (to.meta.auth) {
      debugger
      let login =  Cookies.get('user_key');
      if (login) {
        next.redirect('https://home.fluidsens.embedded.biz.ua/');
        window.location.href = 'https://home.fluidsens.embedded.biz.ua/';
      }
      next.redirect('/');
    } else {
      next();
    }
};



