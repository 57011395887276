import React from 'react'
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from "@material-ui/core/Typography";
import {footerWave} from "../../../../assets/images"
import  Button  from '@material-ui/core/Button';

const styles = theme => ({
  main: {

    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
  },
  text: {
    textAlign: 'center'
  },
  cent: {
    display: 'flex',
    // alignItems: 'center',
    // marginTop: 200s
    flexDirection: 'column',
    minHeight: '100%'
  },

  dividerTop: {
    width:"100%",
    height: 15,
    position: 'absolute',
    top: 0,
    backgroundColor: '#2E5BFF'
  },

  footer: {

  },

  typ: {
    fontStyle: 'normal',
    marginTop: 30, 
    fontWeight: 'bold', 
    marginLeft: "5%", 
    color: '#0F34BB',
    padding:0,
    fontSize: 18,

    //  width: 276, 
     fontFamily: 'Roboto', 
     cursor: 'pointer', 
     textTransform: 'uppercase'

  }
})

const BoxCenter = ({classes, children, logIntoReg}) => {
  return (
    <>
    <div className={classes.cent}>
      <Typography onClick={()=>logIntoReg()} variant="h6"  className={classes.typ}>
        Fluidsens International Inc.
      </Typography>
      <div className={classes.dividerTop}></div>
      <main className={classes.main}>
        <div className={classes.paper}>
          {children}
        </div>
        
      </main>
      
      {/* <svg width="100%" height="174" viewBox="0 0 1600 100" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', bottom: 0}}>
<g clip-path="url(#clip0)">
<path d="M-181.362 22.1914C-181.362 22.1914 -93.9692 223.751 293.002 199.816C599.555 180.823 696.056 39.6341 1019.95 117.835C1232.23 169.097 1392.2 234.023 1576.28 124.716L1605.83 903.337L-224.38 917L-181.362 22.1914Z" fill="url(#paint0_radial)"/>
<path opacity="0.8" d="M-48.8194 39.7708C-48.8194 39.7708 62.7956 182.801 311.604 151.404C560.51 119.91 714.949 -28.837 943.799 31.2433C1172.65 91.3236 1295.7 200.05 1464.48 137.062C1633.25 74.0748 1610.39 779.922 1610.39 779.922H-83.2147L-48.8194 39.7708Z" fill="url(#paint1_radial)"/>
<path opacity="0.7" d="M-184.85 162.035C-184.85 162.035 -87.5746 53.3094 92.6372 38.9677C272.849 24.6259 541.132 204.867 865.998 178.218C1237.85 147.694 1342.4 -37.6832 1537.33 29.4711C1769.96 109.611 1712.8 787.743 1712.8 787.743L-219.149 790.65L-184.85 162.035Z" fill="url(#paint2_radial)"/>
<path opacity="0.5" d="M-203.937 57.0738C-203.937 57.0738 -191.439 152.233 3.88774 141.768C199.214 131.302 412.271 -10.0804 677.938 63.0819C943.605 136.244 1026.54 137.504 1145.71 103.2C1264.89 68.8961 1545.38 71.2218 1612.91 272.2C1680.44 473.179 1647.59 905.66 1647.59 905.66L-230 819.416L-203.937 57.0738Z" fill="url(#paint3_radial)"/>
</g>
<defs>
<radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(461.26 -282.897) scale(1557.42 1557.68)">
<stop stop-color="#CCF2FF"/>
<stop offset="0.1387" stop-color="#85D5F2"/>
<stop offset="0.2909" stop-color="#3EB8E5"/>
<stop offset="0.4006" stop-color="#11A6DC"/>
<stop offset="0.4559" stop-color="#009FD9"/>
<stop offset="0.552" stop-color="#0098D3"/>
<stop offset="0.6901" stop-color="#0085C3"/>
<stop offset="0.8527" stop-color="#0066A8"/>
<stop offset="1" stop-color="#00448B"/>
</radialGradient>
<radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(919.931 -207.495) scale(1287.88 1288.09)">
<stop stop-color="#CCF2FF"/>
<stop offset="0.1387" stop-color="#85D5F2"/>
<stop offset="0.2909" stop-color="#3EB8E5"/>
<stop offset="0.4006" stop-color="#11A6DC"/>
<stop offset="0.4559" stop-color="#009FD9"/>
<stop offset="0.552" stop-color="#0098D3"/>
<stop offset="0.6901" stop-color="#0085C3"/>
<stop offset="0.8527" stop-color="#0066A8"/>
<stop offset="1" stop-color="#00448B"/>
</radialGradient>
<radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(689.409 1036.89) scale(1674.68 1674.95)">
<stop stop-color="#CCF2FF"/>
<stop offset="0.1387" stop-color="#85D5F2"/>
<stop offset="0.2909" stop-color="#3EB8E5"/>
<stop offset="0.4006" stop-color="#11A6DC"/>
<stop offset="0.4559" stop-color="#009FD9"/>
<stop offset="0.552" stop-color="#0098D3"/>
<stop offset="0.6901" stop-color="#0085C3"/>
<stop offset="0.8527" stop-color="#0066A8"/>
<stop offset="1" stop-color="#00448B"/>
</radialGradient>
<radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(235.505 -111.401) scale(1476.05 1476.29)">
<stop stop-color="#CCF2FF"/>
<stop offset="0.1387" stop-color="#85D5F2"/>
<stop offset="0.2909" stop-color="#3EB8E5"/>
<stop offset="0.4006" stop-color="#11A6DC"/>
<stop offset="0.4559" stop-color="#009FD9"/>
<stop offset="0.552" stop-color="#0098D3"/>
<stop offset="0.6901" stop-color="#0085C3"/>
<stop offset="0.8527" stop-color="#0066A8"/>
<stop offset="1" stop-color="#00448B"/>
</radialGradient>
<clipPath id="clip0">
<rect width="1949" height="917" fill="white" transform="translate(-230)"/>
</clipPath>
</defs>
</svg> */}



    </div>
    <img src={footerWave} alt="" width='100%' height='200px' className={classes.footer}/>
    </>
  )
}

export default withStyles(styles)(BoxCenter)
