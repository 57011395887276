import React, { useEffect, useCallback,  } from "react";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import SignInForm from "./form/SignInForm";
import * as actions from "../../redux/actions/authActions/auth.actions";
import BoxCenter from "./UI/box/Center";
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import SignUpForm from './form/SignUpForm';
import { logo } from './../../assets/images';
import { ToastProvider, useToasts } from 'react-toast-notifications';


const SignUp = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts()
  const signUp = useCallback(
    (user, hist, addToast) => dispatch(actions.signUp(user, hist, addToast)),
    [dispatch]
  );

  const isFetching = useSelector(state => state.authReducer.isFetching);

  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  useEffect(()=> {
   if(isLoggedIn)
   props.history.push("/chat");
  }, [isLoggedIn])

  const register = (user ) => {
  
      signUp(user, props.history, addToast)
      .catch((ex) => {
        alert(ex.message);
      });
  }

  return (
    <BoxCenter logIntoReg={()=>props.history.push('/')} >
      
        <img src={logo} width="200px" height="80px"/>
      <Typography component="h1" variant="h5" style={{marginTop: 30, marginBottom: 20, textTransform:'none', fontWeight: 'bold'}}>
        Sign up to register
      </Typography>
      <SignUpForm
        props={props}
        handleSubmit={(data) => register(data)}
        isFetching={isFetching}
        
      />
     
    </BoxCenter>
  );


}

export default SignUp;
