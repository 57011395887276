import React, { useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {useInput} from '../../hooks/useInput'
import validator from 'validator';
import { ToastProvider, useToasts } from 'react-toast-notifications';

const SignUpForm = ({ isFetching, handleSubmit, props }) => {
  const { addToast } = useToasts()
  const { value:confirmPassword, bind:bindConfirmPassword, reset:resetConfirmPassword } = useInput('');
  const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');
  const { value:address, bind:bindAddress, reset:resetAddress } = useInput('');
  const { value:zipcode, bind:bindZipcode, reset:resetZipcode} = useInput('');
  const { value:firstandlastname, bind:bindFirstandlastname, reset:resetFirstandlastname } = useInput('');
  const { value:login, bind:bindLogin, reset:resetLogin } = useInput('');
  const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
  const { value:phone, bind:bindPhone, reset:resetPhone } = useInput('');

  const resetAll = useCallback(() => {


    resetConfirmPassword();
    resetPassword()
    resetAddress()
    resetZipcode()
    resetFirstandlastname()
    resetLogin()
    resetEmail()
    resetPhone()
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule("moreThanThreeChar", (value) => {

      return value.length >= 6;
    });
    ValidatorForm.addValidationRule("notAllowedSpecialSymbols", (value) => {
      return !(/[^A-zА-яЁё0-9 ]/.test(value));
    });
    ValidatorForm.addValidationRule("zipCodeValidation", (value) => {
        return validator.isNumeric (value);
      });
    ValidatorForm.addValidationRule("phoneValidation", (value) => {
        return validator.isMobilePhone(value);
      });

    return () => {
      ValidatorForm.removeValidationRule("moreThanThreeChar");
      ValidatorForm.removeValidationRule("moreThanThreeChar");
    }
  }, [ValidatorForm]);


  const handleSubmitCallback = useCallback((evt) => {
    evt.preventDefault();
    debugger
    if(confirmPassword!==password){
      debugger
      addToast('Passwords mismatch',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true})
      return;
    };
    handleSubmit({login: login, pass: password, mail: email, akk: 0, other_info: {
        address, zipcode, firstandlastname, phone
    }});
  }, [ confirmPassword,  email, password, login,handleSubmit]);

  return (
    <ValidatorForm  onSubmit={handleSubmitCallback}>
      <TextValidator
        fullWidth
        label="First & Last Name"
        name="firstandlastname"
        variant="outlined"
        {...bindFirstandlastname}
        validators={[
          "required",
          "moreThanThreeChar",
          "notAllowedSpecialSymbols",
        ]}
        errorMessages={[
          "this field is required",
          "6 characters minimum",
          "Not allowed special symbols",
        ]}
        margin="dense"
      />
      <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Phone number"
        type="phone"
        {...bindPhone}
        name="phone"
        validators={["required", "phoneValidation"]}
        errorMessages={["this field is required", "invalid phone number"]}
        margin="dense"
      />
            <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Addres"
        {...bindAddress}
        name="address"
        validators={["required"]}
        errorMessages={["this field is required"]}
        margin="dense"
      />
            <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Zip or Postal Code"
        {...bindZipcode}
        name="zipcode"
        validators={["required", "zipCodeValidation"]}
        errorMessages={["this field is required",  "invalid numbers"]}
        margin="dense"
      />
            <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Login Name"
        {...bindLogin}
        name="login"
        validators={[
            "required",
            "moreThanThreeChar",
            "notAllowedSpecialSymbols",
          ]}
          errorMessages={[
            "this field is required",
            "6 characters minimum",
            "Not allowed special symbols",
          ]}
        margin="dense"
      />
            <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Email Account"
        type="email"
        {...bindEmail}
        name="email"
        validators={["required", "isEmail"]}
        errorMessages={["this field is required"]}
        margin="dense"
      />
            <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Password"
        type="password"
        {...bindPassword}
        name="password"
        validators={[
            "required",
            "moreThanThreeChar",
          ]}
          errorMessages={[
            "this field is required",
            "6 characters minimum",
          ]}
        margin="dense"
      />
            <TextValidator
        fullWidth
        style={{height: 44}}
        variant="outlined"
        label="Confirm password"
        type="password"
        {...bindConfirmPassword}
        name="confirmPassword"
        validators={[
            "required",
            "moreThanThreeChar",
          ]}
          errorMessages={[
            "this field is required",
            "6 characters minimum",
          ]}
        margin="dense"
      />
      <div style={{display:"flex", justifyContent: 'space-between'}}>
      <Button
        style={{backgroundColor:"#2E5BFF", color: '#fff', marginTop:15, height:50, width: "49%"}}
        variant="contained"
        type="submit"
        // disabled={isFetching}
        margin="normal"
      >
       Submit
      </Button>
      <Button         style={{backgroundColor:"#B0BAC9", color: '#fff', marginTop:15, height:50, width: "49%"}}
        variant="contained"
        onClick={()=>props.history.push('/')}
        >Exit</Button>
      </div>

    </ValidatorForm>


  );
}




export default SignUpForm;
